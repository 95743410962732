/** @format */

.acss-1g84m4g {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: 1.5px;
  margin-right: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.04);
}
