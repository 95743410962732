.upload_img_div {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 162px;
  max-width: 162px;
  height: 122px;
  max-height: 122px;
  border: 1px dashed #D9D9D9;
  background: #FAFAFA;
}

.upload_img {
  width: 162px;
  height: 122px;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  padding: 8px;
}

.icon_s:hover {
  opacity: 1;
}

#userInfo .ant-image-mask {
  border-radius: 8px;
}