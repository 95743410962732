.main_div {
  height: 100vh;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  background-image: url('@/common/img/login_back.svg');
}

.body_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: auto;
  margin-top: 10%;
}

.logo_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 78px;
}

.logo_div .img {
  width: 52px;
  height: 52px;
  margin-bottom: 16px;
  margin-right: 0;
}

.logo_div .font {
  color: #E6FFF8;
  font-weight: 500;
  font-size: 31px;
  margin-bottom: 12px;
}

.logo_div .font2 {
  display: inline;
  letter-spacing: 19px;
  /* 间距 */
  text-indent: 19px;
  /* 压缩 */
  color: #E6FFF8;
  font-weight: 500;
  font-size: 14px;
}

.img_yzm {
  width: 112px;
  height: 40px;
}

.statement_div {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 12px;
}